// const pre=`http://localhost:2995/`;
// const pre=`http://5.189.169.12:5551/GameTriviaGamesBackend/`;

const pre =`https://backendapi.bigcash.co.za/`; //used this domain

let redirectApi=`${pre}RedirectMtnBigcash`;
export{redirectApi};

let getTermsApi=`${pre}sendTerms`;
export{getTermsApi};

let gameIdUniversal=`1`; //  bigcash mtn new
export{gameIdUniversal};